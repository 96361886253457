import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import EndpointCore from "../../pages/Global.jsx";
import Dropdown from "../dropdownForm/DropdownForm.jsx";
import './style.css';
import lupaIcon from "../../assets/img/icon-search.png";
import jwtDecode from 'jwt-decode';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import useAuth from "../../hook/useAuth.js";

export default function Menu({onGroupLabelClick, hideLogo, hideBuscar, isNavBar }) {
  const [collapsed, setCollapsed] = useState(false);
  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredComunidades, setFilteredComunidades] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [roles, setRoles] = useState(null);
  const [selectedRol, setSelectedRol] = useState('');
  const [collapsedAgrupacion, setCollapsedAgrupacion] = useState(true);
  const [collapsedSolicitudes, setCollapsedSolicitudes] = useState(true);
  const [groupsData, setGroupsData] = useState([]);
  const auth = useAuth();
  const userName = auth.auth.user_name;
  const [userGroups, setUserGroups] = useState([]);
  const [otherGroups, setOtherGroups] = useState([]);
  const [usuarioParticipante, setUsuarioParticipante] = useState(false);
  const [usuarioAuthor, setUsuarioAuthor] = useState(false);
  const [sinAgrupacion, setSinAgrupacion] = useState(false);
  
  console.log ('userGROUPS', userGroups)
  console.log ('othersgoruposid', otherGroups)
  console.log ('participante', usuarioParticipante)
  console.log ('autor', usuarioAuthor)
  console.log ('sin grupo', sinAgrupacion)


  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const userToken = localStorage.getItem('token');
        const response = await fetch(`${EndpointCore.getGroupWithImages}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
              // Se encontró una agrupación, actualizar el estado
              const groupInfo = data.data;  
              setGroupsData(groupInfo);
            } else {
              console.log('No hay agrupación para el autor actual');
            }
          }
      } catch (error) {
        console.error('Error al obtener información de la agrupación:', error);
      }
    }; 
    fetchGroupData();
  }, []);

  useEffect(() => {
    if (groupsData.length > 0) {
      const userGroups = groupsData.filter(group => group.author === userName);
      const otherGroups = groupsData.filter(group => group.author !== userName);
      setUserGroups(userGroups);
      setOtherGroups(otherGroups);
    }
  }, [groupsData, userName]);

  useEffect(() => {
    if (userGroups.length === 0 && otherGroups.length > 0) {
      setUsuarioParticipante(true);
      setUsuarioAuthor(false);
      setSinAgrupacion(false);
    } else if (userGroups.length > 0) {
      setUsuarioAuthor(true);
      setUsuarioParticipante(false);
      setSinAgrupacion(false);
    } else if (userGroups.length === 0 && otherGroups.length === 0) {
      setSinAgrupacion(true);
      setUsuarioParticipante(false);
      setUsuarioAuthor(false);
    }
  }, [userGroups, otherGroups]);

  const fetchComunidades = async () => {
    try {
      const response = await fetch(`${EndpointCore.API_URL}/groups?withImages=true&limitPage=150`);
      const data = await response.json();
      setFilteredComunidades(data.items.map(item => ({
        groupName: item.groupName,
        imagePath: item.images && item.images.length > 0 && item.images[0].image ? item.images[0].image.path : '', 
        groupCode: item.groupCode,
      })));
    } catch (error) {
      console.error('Error al obtener las opciones de comunidad desde la API:', error);
    }
  };
  
  useEffect(() => {
    fetchComunidades();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await fetch(EndpointCore.membersRole);
      if (!response.ok) {
        throw new Error(`Error al obtener los roles: ${response.statusText}`);
      }
      const data = await response.json();
      const filteredRoles = data.slice(1);
      setRoles(filteredRoles);
    } catch (error) {
      console.error('Error al obtener las opciones de roles desde la API:', error);
    }
  };
  
  useEffect(() => {
    fetchRoles();
  }, []);
  const handleModalUnirse = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setSelectedGroup(value);
  };

  const handleRolSelect = (selectedRol) => {
    setSelectedRol(selectedRol);
  };

  const handleUnirse = async () => {
    try {
      const selectedComunidad = filteredComunidades.find(comunidad => comunidad.groupName === selectedGroup);
      if (!selectedComunidad) {
        console.error('No se ha seleccionado ninguna comunidad.');
        return;
      }
  
      // Obtener el JWT almacenado en localStorage
      const token = localStorage.getItem('token');
  
      if (!token) {
        console.error('No se ha encontrado un token JWT en el almacenamiento local.');
        return;
      }
  
      // Decodificar el token para obtener el ID de usuario u otra información relevante si es necesario
       const decodedToken = jwtDecode(token);
       const userId = decodedToken.id;
  
      const response = await fetch(EndpointCore.memberRequest, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({
          group_code: selectedComunidad.groupCode,
          member_type: selectedRol,
          user_id: userId, 
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud de membresía: ${response.statusText}`);
      }
  
      setShow(false);
      toastr.success('Solicitud enviada con éxito');

    } catch (error) {
      console.error('Error al realizar la solicitud de membresía:', error);
      toastr.error(error.message || 'Error al realizar la solicitud de membresía');

    }
  };
  const handleCloseSesion = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };


  return (
<div className=" w-custom sidebar-blue oculta_movile" style={{ transition: "all .3s", width: collapsed ? "80px" : "", borderRadius: '15px'  }}>
  <Link to="/">
     <div className={`${hideLogo ? 'hide-profile' : 'box logo'}`}>
      <div className="d-flex" onClick={() => setCollapsed(!collapsed)}>
        <img src="/img/interna/comunidad_solicitudes/bob.png" alt="bob-solicitudes" />
        {!collapsed && <span>Dmangas</span>}
      </div>
     </div>
    </Link>
      {!collapsed && (
        <div className= {`${hideBuscar ? "hide-profile":"box search"}`}>
          <input type="text" id="search" placeholder="Buscar" name="search" />
        </div>
      )}      
      <div className="box menu-item containerLink ">
        <a href="/solicitudes">
          <img src="/img/interna/comunidad_solicitudes/campana.png" alt="" />
          {!collapsed && "Notificaciones"}
        </a>
        {collapsed && (
          <div className="titleMenu">
            <NavLink to={"/solcitudes"} className="navlink">
              Notificaciones
            </NavLink>
          </div>
        )}
      </div>

      <div className="box menu-item containerLink bordered-container">
        <a href="/profile " className="linkMenu">
          <img src="/img/interna/comunidad_solicitudes/smile.png" alt="" />
          {!collapsed && "Ver perfil"}
        </a>
        {collapsed && (
          <div className="titleMenu">
            <NavLink to={"/profile"} className="navlink">
              Ver perfil
            </NavLink>
          </div>
        )}
      </div>
      {usuarioParticipante && (
      <div className="box menu-item containerLink ">
        <a href="/subir-libro " className="linkMenu">
          <img src="/img/svg/book.svg" alt="" />
          {!collapsed && "Crear Libro"}
        </a>
      </div>
      )}
      {/* Dropdown Agrupación */}
      {usuarioAuthor && (
        <>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne"> 
            <button
               className="accordion-button"
               type="button"
                data-bs-toggle="collapseAgrupacion"
                data-bs-target="#collapseAgrupacion"
                aria-expanded="true"
                aria-controls="collapseAgrupacion"
                onClick={()=> setCollapsedAgrupacion(!collapsedAgrupacion)}
                >
              <img src="/img/interna/comunidad_solicitudes/crea-agrupacion.png" alt="" />
              {!collapsed && "Agrupación"}
            </button>
          </h2>
          {!collapsedAgrupacion && (
            <div id="collapseAgrupacion" className={`accordion-collapse collapse ${collapsedAgrupacion ? '' : 'show'} `} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                {/* Contenido de Agrupación */} 
                <div className="form-check box menu-item">
                  <a style={{ cursor: 'pointer' }}>
                    <img src="/img/svg/wallet.svg" alt="" />
                    <label className="form-check-label" htmlFor="type1">
                      Billetera
                    </label>
                  </a>
                </div>    
                <div className="form-check box menu-item">
                  <a style={{ cursor: 'pointer' }}>
                    <img src="/img/svg/Share.svg" alt="" />
                    <label className="form-check-label" htmlFor="type1">
                    Configuración
                    </label>
                  </a>
                </div>    
                <div className="form-check box menu-item">
                  <a style={{ cursor: 'pointer' }}>
                    <img src="/img/svg/trash-white.svg" alt=""  />
                    <label className="form-check-label" htmlFor="type1">
                      Papelera
                    </label>
                  </a>
                </div>       
                <button className="btn-crear-libro">                   
                <Link to="/subir-libro" >
                 Crear libro
                </Link>
                </button> 
              </div>
            </div>
          )}
        </div>
      </div>
      </>
     )}
      {/* Dropdown Solicitudes */}
      <div className="accordion" id="accordionSolicitudes">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSolicitudes"
              aria-expanded="true"
              aria-controls="collapseSolicitudes"
              onClick={() => setCollapsedSolicitudes(!collapsedSolicitudes)}
            >
              <img src="/img/interna/comunidad_solicitudes/crea-agrupacion.png" alt="" />
              {!collapsed && "Solicitudes"}
            </button>
          </h2>
          {!collapsedSolicitudes && (
            <div id="collapseSolicitudes" className={`accordion-collapse collapse ${collapsedSolicitudes ? '' : 'show'}`} aria-labelledby="headingTwo" data-bs-parent="#accordionSolicitudes">
              <div className="accordion-body">
                {/* Contenido de Solicitudes */}
                <div className="box menu-item containerLink">
                  <Link to="/comunidad-solicitudes">
                    <img src="/img/interna/comunidad_solicitudes/solicitud.png" alt="" />
                    Mis solicitudes
                  </Link>
                </div>     
                <div className="form-check box menu-item">
                  <a onClick={handleModalUnirse} style={{ cursor: 'pointer' }}>
                    <img src="/img/interna/comunidad_solicitudes/unirse.png" alt="" />
                    <label className="form-check-label" htmlFor="type1">
                      Unirse
                    </label>
                  </a>
                </div> 
               
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-check box menu-item bordered-container">
        <a href= "/nueva-agrupacion" >
          <img src="/img/interna/comunidad_solicitudes/subir.png" alt="subir libro" />
          <label
            className="form-check-label custom-text "
            htmlFor="type2"
            onClick={onGroupLabelClick}
            style={{ cursor: 'pointer' } }
          >
            "Crear agrupación"
          </label>
        </a>
      </div>
      {!isNavBar &&(
      <>
      <div className="mi-agrupacion">
    {userGroups && userGroups.length > 0 && (
      <label className="form-check group-label"> 
      MI AGRUPACIÓN
    </label>
    )}
      {userGroups && userGroups.map((group, index) => (
    
  <Link className="box menu-item" key={index} to={`/mis-libros/${group.name.replace(/\s+/g, '-').toLowerCase()}/${group.code}`}>
    <a>
      {group.images && group.images.length > 0 ? (
        <img src={`data:image/png;base64,${group.images[0].base64}`} alt={`Group ${index + 1}`} className="group-image" />
      ) : (
        <div className="group-placeholder">
        </div>       
      )}
      <span className="form-check-label custom-text" style={{marginLeft: '10px'}}>{group.name}</span>
    </a>
  </Link>
))}
</div>
<div className="group-participante" style={{marginTop:'50px'}}>
{otherGroups && otherGroups.length > 0 && (
<label className="form-check group-label"> 
  PARTICIPANTE AGRUPACIÓN
</label>
)}
{otherGroups && otherGroups.map((group, index) => (
    
  <Link className= "box menu-item"key={index} to={`/mis-libros/${group.name.replace(/\s+/g, '-').toLowerCase()}/${group.code}`}>
    <a>
      {group.images && group.images.length > 0 ? (
        <img src={`data:image/png;base64,${group.images[0].base64}`} alt={`Group ${index + 1}`} className="group-image" />
      ) : (
        <div className="group-placeholder">
        </div>       
      )}
      <span className="form-check-label custom-text" style={{marginLeft: '10px'}}>{group.name}</span>
    </a>
  </Link>

))}
</div>
</>
)}
      <div className="box menu-item containerLink">
        <a onClick={()=>handleCloseSesion()} style={{cursor: 'pointer'}}>
          <img src="/img/interna/comunidad_solicitudes/cerrar_sesion.png" alt="" />
          {!collapsed && "Cerrar sesión"}
        </a>
        {collapsed && (
          <div className="titleMenu" style={{cursor: 'pointer'}} >
            <button onClick={()=>handleCloseSesion()} className="navlink" >
              Cerrar sesión
            </button>
          </div>
        )}
      </div>
      <Modal show={show} onHide={closeModal} dialogClassName="custom-dialog">
        <Modal.Header>
          <div className="titulo-blue p-3">UNIRSE</div>
          <div className="ui_btn_close" data-dismiss="modal" onClick={closeModal}>
            <figure className="ui_btn_close__figure">
              <img loading="lazy" src="img/svg/icon_close_FFFFFF.svg" alt="" />
            </figure>
          </div>
        </Modal.Header>
        <div className="modal-unirse-content">
          <div style={{ marginTop: '40px' }}>
            <ul>
              <li>
                <select
                  className="input-search"
                  name="search"
                  id="searchCommunity"
                  value={selectedGroup}
                  onChange={handleSelectChange}
                  style={{
                    backgroundColor: 'rgba(200, 200, 200, 0.5)',
                    padding: '5px',
                    borderRadius: '5px',
                    border: 'none',
                    paddingLeft: '30px',
                    backgroundImage: `url(${lupaIcon})`,
                    backgroundPosition: '5px center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <option value="">Buscar comunidad</option>
                   {filteredComunidades.map((comunidad, index) => (
                   <option key={index} value={comunidad.groupName} style={{display: 'flex'}}>
                     <img src={comunidad.imagePath} style={{ marginLeft: '5px', width:'40px', marginRight: '5px', borderRadius: '25px'}}/>
                     <span>{comunidad.groupName}</span>
                    </option>
                  ))}
                </select>
              </li>
            </ul>
          </div>
          {searchValue ? <div style={{ marginTop: '20px' }}> {searchValue} </div> : null}
          <div className="ibillete historia group-date" style={{ border: 'solid 1px blue', marginTop: '30px', height: '25px', borderRadius: '15px' }} >
            <div>
            <Dropdown
              label={selectedRol ? selectedRol : "Selecciona tu rol"}
              options={roles}
              onSelect={handleRolSelect}
             />
            </div>
          </div>
          <div className="w-100 p-2 d-flex justify-content-center">
          <button id="guardar-cambios" type="button" className="btn-rosado mt-3 px-1 mb-2" onClick={handleUnirse}>
            Unirse
          </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}