import "./style.css";
import React from "react";
import { Link } from "react-router-dom";

function LittleBookEdit({ btnTitle, libroData, handleSubmit }) {
  const { activity_status, alternative_title, author, description, imagePaths, foto, fotoCover, rate, title, demografia, bookTypeAlias, groupName } = libroData;

  console.log('LittleBookEdit libroData:', libroData);

  // Inicializar variables con las imágenes
  let imageCover = '';
  let imagePerfil = '';

  // Si hay imagePaths, intentar asignar los valores
  if (imagePaths && imagePaths.length > 0) {
    const imageCoverObj = imagePaths.find(image => image.view === 'COVER');
    const imagePerfilObj = imagePaths.find(image => image.view === 'PROFILE');
    if (imageCoverObj) {
      imageCover = `data:image/png;base64,${imageCoverObj.base64}`;
    }
    if (imagePerfilObj) {
      imagePerfil = `data:image/png;base64,${imagePerfilObj.base64}`;
    }
  }

  // Si no se encontraron las imágenes en imagePaths, usar foto y fotoCover
  if (!imageCover && fotoCover) {
    imageCover = fotoCover;
  }

  if (!imagePerfil && foto) {
    imagePerfil = foto;
  }

  console.log('imageCover:', imageCover);  // Verifica que la URL es correcta
  console.log('imagePerfil:', imagePerfil);

  const tituloUrl = title?.trim().toLowerCase().replace(/\s+/g, '-');
  const puntuacion = rate;
  const vistas = 'vistas';

  return (
    <div className="LittleBookData">
      <div className="icar item cd">
        <div className="pic-nuevos-capitulos">
          <span>Nuevos capítulos</span>
        </div>
        <div className="info-foto">
          <ul>
            <li className="picture">
              <div className="content-foto">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="key0010type1"
                    defaultValue="true"
                  />
                </div>
                <img
                  className="pic-estrellita"
                  loading="lazy"
                  src="/img/svg/icon_poster_star.svg"
                  alt="Cover"
                />
                <img
                  className="pic-foto"
                  src={imagePerfil || '/path/to/default/profile.png'}
                  alt="Profile"
                />
                <div className="pic-categ">Novela</div>
                <div className="pic-titulo">Amamé con Suavidad</div>
              </div>
            </li>
          </ul>
        </div>
        <div className="info-descripcion">
          <ul>
            <li className="boton puntuacion">
              <div className="number">
                <img
                  className="estrellita"
                  loading="lazy"
                  src="/img/svg/icon_star_purple.svg"
                  alt=""
                />
                <img
                  className="estrellita_white"
                  loading="lazy"
                  src="/img/svg/icon_star_white.svg"
                  alt=""
                />
                <span>8.9 (53)</span>
              </div>
              <div className="red nuevo">Nuevo</div>
            </li>
            <li className="titulo">{title}</li>
            <li className="etitulo">Group:{groupName}</li>
            <li className="subtitulo">
              <div className="botones">
                <button type="button" className="btn red">
                  {bookTypeAlias}
                </button>
                <button type="button" className="btn mora">
                  {demografia}
                </button>
              </div>
            </li>
            <li className="puntuacion">
              <div className="number">
                <span>{puntuacion}</span>
                <img
                  className="estrellita"
                  loading="lazy"
                  src="/img/svg/icon_star_purple.svg"
                  alt=""
                />
                <img
                  className="estrellita_white"
                  loading="lazy"
                  src="/img/svg/icon_star_white.svg"
                  alt=""
                />
              </div>
              <span>Puntuación</span>
            </li>
            <li className="puntuacion">
              <div className="number">
                <span>{vistas}</span>
              </div>
              <span>Vistas</span>
            </li>
            <li className="puntuacion">
              <div className="number">
                <span>8k</span>
              </div>
              <span>Favoritos</span>
            </li>
            <li className="puntuacion">
              <div className="number">
                <span>3k</span>
              </div>
              <span>Comentarios</span>
            </li>
            <li className="vistas">
              <div className="acciones">
                <ul>
                  <li>
                    <img src="img/interna/libro/icon/like.png" alt="" />
                  </li>
                  <li>
                    <img src="img/interna/libro/icon/banderola.png" alt="" />
                  </li>
                  <li>
                    <Link
                      to={`/libros-subir-capitulo/${tituloUrl}` }
                      state={{ libroData: libroData, }}
                      className="btn btn_rosado edit" style={{ display: 'flex' }}
                      onClick={handleSubmit}
                    >
                      <img src="/img/interna/comunidad_solicitudes/subir.png" style={{ marginRight: '10px' }} />
                      <span>Subir capítulo</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="linea_completa" />
        <div className="linea_completa2">
          <div className="linea_media_left" />
          <div className="linea_media_right" />
        </div>
      </div>
      <div className="lbox_container clasificado icontainer slider oculta_desktop">
        <div className="clasificado">
          <ul className="slider_data">
            <li>
              <span>{puntuacion} <img src="img/interna/libro/icon/estrella.png" alt="" /> </span> Puntuación
            </li>
            <li>
              <span>{vistas}</span> Vistas
            </li>
            <li>
              <span>8k</span> Favoritos
            </li>
            <li>
              <span>3k</span> Comentarios
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LittleBookEdit;