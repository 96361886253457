import '../assets/css/App.css';
import '../assets/css/interna.css';
import '../assets/css/movil.css';
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Menu from '../components/menu/Menu';
import Banner from '../components/banner/Banner';
import Footer from '../components/footer/Footer';
import Carrusel from '../components/carrusel/Carrusele';
import EndpointCore from './Global';
import ChapterViewer from '../components/chaptersViewer/ChapterVIewer';
import CommentBox from '../components/comments/CommentBox';
import ReportarDropdown from '../components/reportarDropdown/ReportarDropdown';
import useAuth from '../hook/useAuth';

function BooksReadChapter() {
    const params = useParams();
    const { bookCode, chapterCode } = params; // Obtén bookCode y chapterCode desde params
    const { auth } = useAuth();
    const userId = auth.id;
    const [chapters, setChapters] = useState([]);
    const [specificChapter, setSpecificChapter] = useState([]);
    const [showReportDropdown, setShowReportDropdown] = useState(false);

    const handleReportDropdownClick = () => {
        setShowReportDropdown(!showReportDropdown);
    };

    useEffect(() => {
        const fetchChapters = async () => {
            try {
                if (!chapterCode || !bookCode) {
                    throw new Error('Faltan parámetros para obtener los capítulos');
                }

                const requestOptions = {
                    method: "GET",
                    redirect: "follow",
                    headers: {}
                };

                const response = await fetch(`${EndpointCore.getChapters}?bookCode=${bookCode}&chapterCode=${chapterCode}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error al obtener los capítulos');
                }

                const result = await response.json();
                console.log(result);

                let urlDocuments = [];
                if (result.specificChapter.urlDocument) {
                    urlDocuments = result.specificChapter.urlDocument.map(url => {
                        if (url.startsWith('data:application/pdf;base64,')) {
                            return url;
                        } else {
                            return url;
                        }
                    });
                }

                setChapters(result.chapters);
                setSpecificChapter({
                    ...result.specificChapter,
                    urlDocument: urlDocuments
                });
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchChapters();
    }, [chapterCode, bookCode]);

    useEffect(() => {
        const updateChapterHistory = async () => {
            try {
                if (!userId || !specificChapter.bookId || !specificChapter.groupId || !specificChapter.chapterId) {
                    console.error('Faltan parámetros para actualizar el historial.');
                    return;
                }
    
                const requestOptionsGet = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
    
                // Busca si ya existe un historial con ese bookId
                const responseGet = await fetch(EndpointCore.getUserHistory(userId, specificChapter.bookId), requestOptionsGet);
                const resultGet = await responseGet.json();
    
                if (responseGet.ok && resultGet) {
                    // Si ya existe un historial, actualiza con PUT
                    const requestOptionsPut = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            userId: userId,
                            bookId: specificChapter.bookId,
                            groupId: specificChapter.groupId,
                            chapterId: specificChapter.chapterId,
                        }),
                    };
                    const responsePut = await fetch(`${EndpointCore.updateUserHistory}`, requestOptionsPut);
                    if (!responsePut.ok) {
                        throw new Error('Error al actualizar el historial.');
                    }
                } else {
                    // Si no existe, crea uno nuevo con POST
                    const requestOptionsPost = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            userId: userId,
                            bookId: specificChapter.bookId,
                            groupId: specificChapter.groupId,
                            chapterId: specificChapter.chapterId,
                        }),
                    };
                    const responsePost = await fetch(`${EndpointCore.createUserHistory}`, requestOptionsPost);
                    if (!responsePost.ok) {
                        throw new Error('Error al crear un nuevo registro en el historial.');
                    }
                }
            } catch (error) {
                console.error('Error actualizando el historial:', error);
            }
        };
    
        // Llamar a la función si specificChapter tiene los datos requeridos
        if (specificChapter.bookId && specificChapter.chapterId && userId) {
            updateChapterHistory();
        }
    }, [specificChapter, userId]);
    

    console.log('capitulos', chapters);
    console.log('specificChapter', specificChapter);

    const items_banner = [{ foto_movil: 'img/interna/libro_detalle/publicidad.png', foto: 'img/interna/libro_detalle/publicidad.png', texto: `PUBLICIDAD` }];
    const ibanner = [{ foto_movil: '/img/banner/slider-trasparente.png', foto: '/img/banner/slider-trasparente.png', texto: `` }];
    const banner02 = [{ foto_movil: '/img/interna/libro_detalle/slider/slider1.jpg', foto: '/img/interna/libro_detalle/slider/slider1.jpg', texto: `` }];

    var configRecomendacion = { rows: 1, draggable: true, slidesToShow: 2, slidesToScroll: 2, responsive: { slidesToShow: 2, slidesToScroll: 2, rows: 1 } };

    const [itemsRecommendation, setItemsRecommendation] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {}
        };
        fetch(EndpointCore.recommends, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                let data_items = [];
                result.items.forEach((i, index) => {
                    data_items.push({
                        "id": index + 1,
                        "foto": i.images[0].image.path,
                        "texto": i.bookTitle,
                        "capitulo": "capitulo",
                        "novela": i.bookType.name.value,
                        "time": "Hace " + i.attributes.bookHourLatest + " horas",
                        "manga": "Seinen",
                        "shoujo": "Novela",
                        "puntuacion": i.bookRate,
                        "vistas": i.bookLabelViews
                    });
                });
                setItemsRecommendation(data_items);
            })
            .catch((error) => console.log("error", error));
    }, []);

    const closeDropdown = () => {
        setShowReportDropdown(false);
    };
     
    return (
        <div className="interna BookReadChapter  ">
            <div className='position_header position hide_movil ' >
                <div className='ui_header' style={{paddingLeft:'280px'}}><Menu></Menu></div>
                <div className='top-banner'><Banner items_banner={ibanner} heightAuto={true}></Banner></div>
            </div>
            <div className='position_top_A position'>
                <div className='container'>
                    <div className='banner-simple hide_movil'><Banner items_banner={items_banner} heightAuto={true}></Banner></div>
                </div>
                <div className='container'>
                <div className="psidebar">
                        <div className='box-row subtitulo margin-button-20'>
                            <span>Publicado por: </span>
                        </div>
                        <ul className="rz-photo publicado_por">
                            <li><img src="img/interna/libro_detalle/icon_avatar.png" alt="" /></li>
                            <li>
                                <div className="li-publi"><span>Somos party</span></div>
                                <div className="li-publi">
                                    <img src="/img/interna/libro_detalle/user2.png" alt="" />
                                    <span>253K</span>
                                </div>
                            </li>
                            <li><button type="button" className="globo_red">Seguir</button></li>
                        </ul>
                        <ul className="rz-photo social-icon">
                            <li>
                                <img src="/img/interna/libro_detalle/icon_item1.jpg" alt="" />
                                <img src="/img/interna/libro_detalle/icon_item2.jpg" alt="" />
                                <img src="/img/interna/libro_detalle/icon_item3.jpg" alt="" />
                            </li>
                            <li>
                                <img src="/img/interna/libro_detalle/icon_item4.jpg" alt="" />
                                <img src="/img/interna/libro_detalle/icon_item5.jpg" alt="" />
                                <img src="/img/interna/libro_detalle/icon_item6.jpg" alt="" />
                            </li>
                        </ul>
                        <div className="bgblue color_white icomentario">
                            <div className='container recomendation'>
                                <Carrusel icarrusel={itemsRecommendation} key_position={'key003'} titulo={'Recomendados'} config={configRecomendacion}></Carrusel>
                            </div>
                        </div>
                   </div>
                    <div className="pcontent">
                        <div className="lbox_container oculta_desktop">
                            <ul className="header-icon-mobile-detalle-libro">
                                <li className="icon1">
                                    <a href="libros-detalle"><img src="img/interna/libro_detalle/m-prev.png" alt="" />
                                        <span>Cápitulo 1</span></a>
                                </li>
                                <li className="icon2">
                                    <img src="img/interna/libro_detalle/m-share.png" alt="" />
                                    <img src="img/interna/libro_detalle/m-estrella.png" alt="" />
                                    <img src="img/interna/libro_detalle/m-tres.png" alt="" />
                                </li>
                            </ul>
                        </div>

                        <div className='continer-slider'>
                            <div className="itext ">
                                <div className="titulo"> Cap. {specificChapter.number}</div>
                                <div className='container-reportar-chapter'>
                                  <div className="subtitulo">{specificChapter.name}</div>
                                  <ul className="header-icon-mobile-detalle-libro">
            <li className="icon1">
              <a href="libros">
                <img src="/img/interna/libro_detalle/m-prev.png" alt="" />
              </a>
            </li>
            <li className="icon2">
              <a>
                <img src="/img/interna/libro_detalle/m-share.png" alt="" />{" "}
              </a>
              <a className="reportar-movil" onClick={handleReportDropdownClick}>
                <img
                  src="/img/interna/libro_detalle/m-tres.png"
                  alt="denuncia"
                />
              </a>
              <div className="reportar">
                {showReportDropdown && <ReportarDropdown user_id={userId} entity_id={specificChapter.chapterId} notification_type={'CHAPTER'} closeDropdown={closeDropdown}/>}
              </div>
            </li>
             
          </ul>
                                </div>
                            </div>
                            <div className='Banner02'><Banner items_banner={banner02}></Banner></div>
                            <div className='Banner02'><Banner items_banner={banner02}></Banner></div>
                            <div className='Banner02'><Banner items_banner={banner02}></Banner></div>
                              {/* ChapterViewer */}
                            <div id="carouselExampleControls" className="carousel slide">
                            <div style={{  }}>
                               {specificChapter && <ChapterViewer specificChapter={specificChapter} />}
                              </div>
                            </div>
                             {/* ChapterViewer */}
                            <div id="slider_bootom" className="w-full bgwhite info ">
                                <div className="grupo1">
                                    <img data-toggle="collapse" data-target="#lista-capitulos" style={{ cursor: 'pointer' }} src="img/interna/libro_detalle/hamburger.svg" alt="" />
                                    <span>Capítulos</span>
                                </div>
                                <div className="grupo2 pagination owl-nav">
                                    <button type="button">Anterior</button>
                                    <button type="button">Siguiente</button>
                                </div>
                            </div>
                            {/* lista-capitulos */}
                            <div id="lista-capitulos" className="w-full bgwhite collapse oculta_movile">
                             {chapters.map((chapter, index) => (
                              <div className="item w-full" key={index}>
                                  <div className="titulo w-full">Cap. {chapter.number}</div>
                                  <div className="subtitulo w-full">{chapter.name || 'Nombre no disponible'}</div>
                              </div>
                             ))}
                              </div>
                            {/* lista-capitulos */}
                        </div>
                        <div className="icontainer slider bggris">
                            {/* item*/}
                            <div className="w-full bgwhite top oculta_desktop">
                                <div className="lbox_container">
                                    <div className="lbox_bold bgwhite">Publicado por:</div>
                                    <div className="lbox_normal">
                                        <ul className="rz-photo publicado_por">
                                            <li><img src="img/interna/libro_detalle/icon_avatar.png" alt="" /></li>
                                            <li>
                                                <div className="li-publi"><span>Somos party</span></div>
                                                <div className="li-publi">
                                                    <img src="img/interna/libro_detalle/user2.png" alt="" />
                                                    <span>253K</span>
                                                </div>
                                            </li>
                                            <li><button type="button" className="globo_red">Seguir</button></li>
                                        </ul>
                                    </div>
                                    <div className="lbox_normal">
                                        <ul className="rz-photo social-icon">
                                            <li>
                                                <img src="img/interna/libro_detalle/icon_item1.jpg" alt="" />
                                                <img src="img/interna/libro_detalle/icon_item2.jpg" alt="" />
                                                <img src="img/interna/libro_detalle/icon_item3.jpg" alt="" />
                                            </li>
                                            <li>
                                                <img src="img/interna/libro_detalle/icon_item4.jpg" alt="" />
                                                <img src="img/interna/libro_detalle/icon_item5.jpg" alt="" />
                                                <img src="img/interna/libro_detalle/icon_item6.jpg" alt="" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* item8*/}
                            {/* item*/}
                            <CommentBox specificChapter={specificChapter}/>                         {/* item*/}
                            {/* pagination */}
                            <div id="movil-slider-pagination" className="w-full paginacion-movil bgwhite info oculta_desktop">
                                <div className="grupo2 pagination">
                                    <button type="button">Anterior</button>
                                    <img onclick="openModal('lista_capitulos01')" className="btn-hamburger" src="img/interna/libro_detalle/hamburger.svg" alt="" />
                                    <input type="hidden" id="contador" name="contador" defaultValue={0} />
                                    <button type="button">Siguiente</button>
                                </div>
                            </div>
                            {/* pagination */}
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default BooksReadChapter;
