import React from "react";
import MenuLeft from "../menu-left/index";

export default function index() {
  return (
     
      <div className="p-4 w-full" style={{width: '360px'}}>
     
        <div className="profile-modal" >
        
               <MenuLeft hideLogo={true} hideBuscar={true} isNavBar={true}/>
          </div>
      </div>
    );
  }
